/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileDTO
 */
export interface UserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    profilePictureId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDTO
     */
    removeProfilePictureId?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    profileDescription?: string;
}

/**
 * Check if a given object implements the UserProfileDTO interface.
 */
export function instanceOfUserProfileDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function UserProfileDTOFromJSON(json: any): UserProfileDTO {
    return UserProfileDTOFromJSONTyped(json, false);
}

export function UserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'profilePictureId': !exists(json, 'profilePictureId') ? undefined : json['profilePictureId'],
        'removeProfilePictureId': !exists(json, 'removeProfilePictureId') ? undefined : json['removeProfilePictureId'],
        'profileDescription': !exists(json, 'profileDescription') ? undefined : json['profileDescription'],
    };
}

export function UserProfileDTOToJSON(value?: UserProfileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'profilePictureId': value.profilePictureId,
        'removeProfilePictureId': value.removeProfilePictureId,
        'profileDescription': value.profileDescription,
    };
}

