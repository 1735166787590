/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalingOffer } from './SignalingOffer';
import {
    SignalingOfferFromJSON,
    SignalingOfferFromJSONTyped,
    SignalingOfferToJSON,
} from './SignalingOffer';

/**
 * 
 * @export
 * @interface CreateCallRequest
 */
export interface CreateCallRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCallRequest
     */
    calleeId: string;
    /**
     * 
     * @type {SignalingOffer}
     * @memberof CreateCallRequest
     */
    offer: SignalingOffer;
}

/**
 * Check if a given object implements the CreateCallRequest interface.
 */
export function instanceOfCreateCallRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "calleeId" in value;
    isInstance = isInstance && "offer" in value;

    return isInstance;
}

export function CreateCallRequestFromJSON(json: any): CreateCallRequest {
    return CreateCallRequestFromJSONTyped(json, false);
}

export function CreateCallRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCallRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calleeId': json['calleeId'],
        'offer': SignalingOfferFromJSON(json['offer']),
    };
}

export function CreateCallRequestToJSON(value?: CreateCallRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calleeId': value.calleeId,
        'offer': SignalingOfferToJSON(value.offer),
    };
}

