/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountBalance,
  AnswerCallRequest,
  BlockedUserDTO,
  Call,
  ChatDTO,
  ChatMemberDTO,
  CreateCallRequest,
  EndCallRequest,
  GetMedia200Response,
  GetMessages200Response,
  GroupDTO,
  GroupProfileDTO,
  Message,
  MessageFetchDirection,
  PageBlockedUserDTO,
  PageChatDTO,
  PageChatMemberDTO,
  Pageable,
  PostNewSignalingCandidateRequest,
  SignalingCandidate,
  UserDTO,
  UserProfileDTO,
  UserSettingsDTO,
} from '../models/index';
import {
    AccountBalanceFromJSON,
    AccountBalanceToJSON,
    AnswerCallRequestFromJSON,
    AnswerCallRequestToJSON,
    BlockedUserDTOFromJSON,
    BlockedUserDTOToJSON,
    CallFromJSON,
    CallToJSON,
    ChatDTOFromJSON,
    ChatDTOToJSON,
    ChatMemberDTOFromJSON,
    ChatMemberDTOToJSON,
    CreateCallRequestFromJSON,
    CreateCallRequestToJSON,
    EndCallRequestFromJSON,
    EndCallRequestToJSON,
    GetMedia200ResponseFromJSON,
    GetMedia200ResponseToJSON,
    GetMessages200ResponseFromJSON,
    GetMessages200ResponseToJSON,
    GroupDTOFromJSON,
    GroupDTOToJSON,
    GroupProfileDTOFromJSON,
    GroupProfileDTOToJSON,
    MessageFromJSON,
    MessageToJSON,
    MessageFetchDirectionFromJSON,
    MessageFetchDirectionToJSON,
    PageBlockedUserDTOFromJSON,
    PageBlockedUserDTOToJSON,
    PageChatDTOFromJSON,
    PageChatDTOToJSON,
    PageChatMemberDTOFromJSON,
    PageChatMemberDTOToJSON,
    PageableFromJSON,
    PageableToJSON,
    PostNewSignalingCandidateRequestFromJSON,
    PostNewSignalingCandidateRequestToJSON,
    SignalingCandidateFromJSON,
    SignalingCandidateToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
    UserProfileDTOFromJSON,
    UserProfileDTOToJSON,
    UserSettingsDTOFromJSON,
    UserSettingsDTOToJSON,
} from '../models/index';

export interface AddBlockedUserRequest {
    userId: string;
    blockUserId: string;
}

export interface AddGroupMemberRequest {
    groupId: string;
    userId: string;
}

export interface AddGroupMembersRequest {
    groupId: string;
    requestBody?: Array<string>;
}

export interface AnswerCallOperationRequest {
    answerCallRequest: AnswerCallRequest;
}

export interface CreateCallOperationRequest {
    createCallRequest: CreateCallRequest;
}

export interface CreateDirectChatRequest {
    userId: string;
    otherUserId: string;
}

export interface CreateGroupRequest {
    userId: string;
    groupProfileDTO: GroupProfileDTO;
}

export interface CreateUserRequest {
    userId: string;
    userProfileDTO: UserProfileDTO;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface EndCallOperationRequest {
    endCallRequest?: EndCallRequest;
}

export interface GetAllChatsRequest {
    userId: string;
    pageable: Pageable;
}

export interface GetBlockedUsersRequest {
    userId: string;
    pageable: Pageable;
}

export interface GetChatRequest {
    chatId: string;
    userId: string;
}

export interface GetChatMembersRequest {
    chatId: string;
    userId: string;
    pageable: Pageable;
}

export interface GetChatStatusRequest {
    chatId: string;
    userId: string;
}

export interface GetFundsRequest {
    userId: string;
}

export interface GetGroupRequest {
    groupId: string;
}

export interface GetGroupProfileRequest {
    groupId: string;
}

export interface GetGroupRoleRequest {
    groupId: string;
    userId: string;
}

export interface GetMediaRequest {
    objectName: string;
}

export interface GetMessagesRequest {
    chatId: string;
    fromDate: Date;
    page?: number;
    pageSize?: number;
    direction?: MessageFetchDirection;
}

export interface GetSignalingCandidatesRequest {
    callId: string;
}

export interface GetUserRequest {
    userId: string;
}

export interface GetUserIdByEmailRequest {
    email: string;
}

export interface GetUserProfileRequest {
    userId: string;
}

export interface GetUserSettingsRequest {
    userId: string;
}

export interface IsBlockedUserRequest {
    userId: string;
    isBlockedId: string;
}

export interface PostNewSignalingCandidateOperationRequest {
    postNewSignalingCandidateRequest?: PostNewSignalingCandidateRequest;
}

export interface RemoveBlockedUserRequest {
    userId: string;
    unblockUserId: string;
}

export interface RemoveGroupMemberRequest {
    groupId: string;
    userId: string;
}

export interface SendMessageRequest {
    message: Message;
}

export interface SetChatStatusRequest {
    chatId: string;
    userId: string;
    body: string;
}

export interface UpdateGroupProfileRequest {
    groupId: string;
    groupProfileDTO: GroupProfileDTO;
}

export interface UpdateGroupRoleRequest {
    groupId: string;
    userId: string;
    body: string;
}

export interface UpdateTokenRequest {
    notificationToken: string;
}

export interface UpdateUserProfileRequest {
    userId: string;
    userProfileDTO: UserProfileDTO;
}

export interface UpdateUserSettingsRequest {
    userId: string;
    userSettingsDTO: UserSettingsDTO;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * A user cannot block themselves. There is a limit on the number of users a user can block. A user cannot block a user that they have already blocked. Returns the same object as getBlockedUsers.
     * Add a user to the blocklist.
     */
    async addBlockedUserRaw(requestParameters: AddBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlockedUserDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addBlockedUser.');
        }

        if (requestParameters.blockUserId === null || requestParameters.blockUserId === undefined) {
            throw new runtime.RequiredError('blockUserId','Required parameter requestParameters.blockUserId was null or undefined when calling addBlockedUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.blockUserId !== undefined) {
            queryParameters['blockUserId'] = requestParameters.blockUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/blockedUsers`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockedUserDTOFromJSON(jsonValue));
    }

    /**
     * A user cannot block themselves. There is a limit on the number of users a user can block. A user cannot block a user that they have already blocked. Returns the same object as getBlockedUsers.
     * Add a user to the blocklist.
     */
    async addBlockedUser(requestParameters: AddBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlockedUserDTO> {
        const response = await this.addBlockedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The new user is added as a GROUP_MEMBER. One cannot add someone who is already part of the group. Returns the same object as getChatMember.
     * Add a new user to the group.
     */
    async addGroupMemberRaw(requestParameters: AddGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatMemberDTO>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling addGroupMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addGroupMember.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/member`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMemberDTOFromJSON(jsonValue));
    }

    /**
     * The new user is added as a GROUP_MEMBER. One cannot add someone who is already part of the group. Returns the same object as getChatMember.
     * Add a new user to the group.
     */
    async addGroupMember(requestParameters: AddGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatMemberDTO> {
        const response = await this.addGroupMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The new users are added as a GROUP_MEMBER. If some emails could not be found they fail silently
     * Add new users to the group via their email.
     */
    async addGroupMembersRaw(requestParameters: AddGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChatMemberDTO>>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling addGroupMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/members`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatMemberDTOFromJSON));
    }

    /**
     * The new users are added as a GROUP_MEMBER. If some emails could not be found they fail silently
     * Add new users to the group via their email.
     */
    async addGroupMembers(requestParameters: AddGroupMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChatMemberDTO>> {
        const response = await this.addGroupMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Answer a call
     */
    async answerCallRaw(requestParameters: AnswerCallOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.answerCallRequest === null || requestParameters.answerCallRequest === undefined) {
            throw new runtime.RequiredError('answerCallRequest','Required parameter requestParameters.answerCallRequest was null or undefined when calling answerCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calling/call/answer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnswerCallRequestToJSON(requestParameters.answerCallRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Answer a call
     */
    async answerCall(requestParameters: AnswerCallOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.answerCallRaw(requestParameters, initOverrides);
    }

    /**
     * Create a call
     */
    async createCallRaw(requestParameters: CreateCallOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Call>> {
        if (requestParameters.createCallRequest === null || requestParameters.createCallRequest === undefined) {
            throw new runtime.RequiredError('createCallRequest','Required parameter requestParameters.createCallRequest was null or undefined when calling createCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calling/call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCallRequestToJSON(requestParameters.createCallRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallFromJSON(jsonValue));
    }

    /**
     * Create a call
     */
    async createCall(requestParameters: CreateCallOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Call> {
        const response = await this.createCallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A user cannot create a chat with themselves and there cannot exist more than one chat between two users. Returns the same object as getChat. UserId refers to the user in relation to which the ChatDTO should be returned.
     * Create a direct chat between two users.
     */
    async createDirectChatRaw(requestParameters: CreateDirectChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createDirectChat.');
        }

        if (requestParameters.otherUserId === null || requestParameters.otherUserId === undefined) {
            throw new runtime.RequiredError('otherUserId','Required parameter requestParameters.otherUserId was null or undefined when calling createDirectChat.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.otherUserId !== undefined) {
            queryParameters['otherUserId'] = requestParameters.otherUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats/directChats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatDTOFromJSON(jsonValue));
    }

    /**
     * A user cannot create a chat with themselves and there cannot exist more than one chat between two users. Returns the same object as getChat. UserId refers to the user in relation to which the ChatDTO should be returned.
     * Create a direct chat between two users.
     */
    async createDirectChat(requestParameters: CreateDirectChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatDTO> {
        const response = await this.createDirectChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The initial user is the only member and admin of the newly created group. The name field of group profile is required. If no description is provided a default description will be set. Picture id is optional. Returns the same structure as getGroup.
     * Create a group with an initial user.
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createGroup.');
        }

        if (requestParameters.groupProfileDTO === null || requestParameters.groupProfileDTO === undefined) {
            throw new runtime.RequiredError('groupProfileDTO','Required parameter requestParameters.groupProfileDTO was null or undefined when calling createGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupProfileDTOToJSON(requestParameters.groupProfileDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDTOFromJSON(jsonValue));
    }

    /**
     * The initial user is the only member and admin of the newly created group. The name field of group profile is required. If no description is provided a default description will be set. Picture id is optional. Returns the same structure as getGroup.
     * Create a group with an initial user.
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDTO> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * One cannot create a user which exists already. If the profile description is empty, a default value is used. ProfilePictureId is optional. The other fields are required. RemoveProfilePicture must be null. The email must not exist in the system yet.
     * Create a user with an initial profile.
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createUser.');
        }

        if (requestParameters.userProfileDTO === null || requestParameters.userProfileDTO === undefined) {
            throw new runtime.RequiredError('userProfileDTO','Required parameter requestParameters.userProfileDTO was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileDTOToJSON(requestParameters.userProfileDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * One cannot create a user which exists already. If the profile description is empty, a default value is used. ProfilePictureId is optional. The other fields are required. RemoveProfilePicture must be null. The email must not exist in the system yet.
     * Create a user with an initial profile.
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The deletion process includes all blocked relations and all chat memberships.
     * Delete a user from the Social service.
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The deletion process includes all blocked relations and all chat memberships.
     * Delete a user from the Social service.
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * End a video call
     */
    async endCallRaw(requestParameters: EndCallOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calling/call/end`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EndCallRequestToJSON(requestParameters.endCallRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * End a video call
     */
    async endCall(requestParameters: EndCallOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.endCallRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a page of the same objects that getChat returns. Page size is not allowed to exceed 1000.
     * Fetch all chats which a user is part of.
     */
    async getAllChatsRaw(requestParameters: GetAllChatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageChatDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getAllChats.');
        }

        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getAllChats.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageChatDTOFromJSON(jsonValue));
    }

    /**
     * Returns a page of the same objects that getChat returns. Page size is not allowed to exceed 1000.
     * Fetch all chats which a user is part of.
     */
    async getAllChats(requestParameters: GetAllChatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageChatDTO> {
        const response = await this.getAllChatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a page of ids, profiles, and timestamps when the user has been blocked. Page size is not allowed to exceed 1000.
     * Fetch the blocklist for a user.
     */
    async getBlockedUsersRaw(requestParameters: GetBlockedUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageBlockedUserDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getBlockedUsers.');
        }

        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getBlockedUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/blockedUsers`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBlockedUserDTOFromJSON(jsonValue));
    }

    /**
     * Returns a page of ids, profiles, and timestamps when the user has been blocked. Page size is not allowed to exceed 1000.
     * Fetch the blocklist for a user.
     */
    async getBlockedUsers(requestParameters: GetBlockedUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageBlockedUserDTO> {
        const response = await this.getBlockedUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the chat id and chat type. If it is a group chat, the group profile will also be returned. If it is direct chat, the user profile of the other user will be returned. If the other user of a direct chat does not exist anymore, it will still return the id of the other user but no longer the user profile.
     * Fetch the general information about a chat for specific user.
     */
    async getChatRaw(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatDTO>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling getChat.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getChat.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats/{chatId}`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatDTOFromJSON(jsonValue));
    }

    /**
     * Returns the chat id and chat type. If it is a group chat, the group profile will also be returned. If it is direct chat, the user profile of the other user will be returned. If the other user of a direct chat does not exist anymore, it will still return the id of the other user but no longer the user profile.
     * Fetch the general information about a chat for specific user.
     */
    async getChat(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatDTO> {
        const response = await this.getChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user ids, profiles, and if it is a group chat, also the roles of each member. Page size is not allowed to exceed 1000.
     * Fetch all members of a chat.
     */
    async getChatMembersRaw(requestParameters: GetChatMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageChatMemberDTO>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling getChatMembers.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getChatMembers.');
        }

        if (requestParameters.pageable === null || requestParameters.pageable === undefined) {
            throw new runtime.RequiredError('pageable','Required parameter requestParameters.pageable was null or undefined when calling getChatMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.pageable !== undefined) {
            queryParameters['pageable'] = requestParameters.pageable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats/{chatId}/members`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageChatMemberDTOFromJSON(jsonValue));
    }

    /**
     * Returns the user ids, profiles, and if it is a group chat, also the roles of each member. Page size is not allowed to exceed 1000.
     * Fetch all members of a chat.
     */
    async getChatMembers(requestParameters: GetChatMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageChatMemberDTO> {
        const response = await this.getChatMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * If the user is not part of the chat, NOT_A_MEMBER is returned.
     * Fetch the chat status for a given chat and user.
     */
    async getChatStatusRaw(requestParameters: GetChatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling getChatStatus.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getChatStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats/{chatId}/members/{userId}/status`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * If the user is not part of the chat, NOT_A_MEMBER is returned.
     * Fetch the chat status for a given chat and user.
     */
    async getChatStatus(requestParameters: GetChatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getChatStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get fund balance
     */
    async getFundsRaw(requestParameters: GetFundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountBalance>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getFunds.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment/funds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountBalanceFromJSON(jsonValue));
    }

    /**
     * Get fund balance
     */
    async getFunds(requestParameters: GetFundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountBalance> {
        const response = await this.getFundsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the group id and group profile.
     * Fetch the information about a group.
     */
    async getGroupRaw(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDTO>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDTOFromJSON(jsonValue));
    }

    /**
     * Returns the group id and group profile.
     * Fetch the information about a group.
     */
    async getGroup(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDTO> {
        const response = await this.getGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All returned fields are populated. RemoveProfilePictureId is null.
     * Fetch the group profile for a given group.
     */
    async getGroupProfileRaw(requestParameters: GetGroupProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupProfileDTO>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroupProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/profile`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupProfileDTOFromJSON(jsonValue));
    }

    /**
     * All returned fields are populated. RemoveProfilePictureId is null.
     * Fetch the group profile for a given group.
     */
    async getGroupProfile(requestParameters: GetGroupProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupProfileDTO> {
        const response = await this.getGroupProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * If the user is not part of the group, NOT_A_MEMBER is returned.
     * Fetch the role of a user for a group.
     */
    async getGroupRoleRaw(requestParameters: GetGroupRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroupRole.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getGroupRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/members/{userId}/role`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * If the user is not part of the group, NOT_A_MEMBER is returned.
     * Fetch the role of a user for a group.
     */
    async getGroupRole(requestParameters: GetGroupRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getGroupRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get media data
     */
    async getMediaRaw(requestParameters: GetMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedia200Response>> {
        if (requestParameters.objectName === null || requestParameters.objectName === undefined) {
            throw new runtime.RequiredError('objectName','Required parameter requestParameters.objectName was null or undefined when calling getMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.objectName !== undefined) {
            queryParameters['objectName'] = requestParameters.objectName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/media/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedia200ResponseFromJSON(jsonValue));
    }

    /**
     * Get media data
     */
    async getMedia(requestParameters: GetMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedia200Response> {
        const response = await this.getMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get messages of a chat
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMessages200Response>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling getMessages.');
        }

        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.chatId !== undefined) {
            queryParameters['chatId'] = requestParameters.chatId;
        }

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString();
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMessages200ResponseFromJSON(jsonValue));
    }

    /**
     * Get messages of a chat
     */
    async getMessages(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMessages200Response> {
        const response = await this.getMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all signaling candidates for a call
     */
    async getSignalingCandidatesRaw(requestParameters: GetSignalingCandidatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SignalingCandidate>>> {
        if (requestParameters.callId === null || requestParameters.callId === undefined) {
            throw new runtime.RequiredError('callId','Required parameter requestParameters.callId was null or undefined when calling getSignalingCandidates.');
        }

        const queryParameters: any = {};

        if (requestParameters.callId !== undefined) {
            queryParameters['callId'] = requestParameters.callId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calling/call/candidates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignalingCandidateFromJSON));
    }

    /**
     * Get all signaling candidates for a call
     */
    async getSignalingCandidates(requestParameters: GetSignalingCandidatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SignalingCandidate>> {
        const response = await this.getSignalingCandidatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user id, profile, and settings.
     * Fetch the general information about a user.
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Returns the user id, profile, and settings.
     * Fetch the general information about a user.
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user id if it exists.
     * Get the user id for an email.
     */
    async getUserIdByEmailRaw(requestParameters: GetUserIdByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getUserIdByEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/byEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the user id if it exists.
     * Get the user id for an email.
     */
    async getUserIdByEmail(requestParameters: GetUserIdByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getUserIdByEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All returned fields are populated. RemoveProfilePictureId is null.
     * Fetch the profile of a user.
     */
    async getUserProfileRaw(requestParameters: GetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/profile`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     * All returned fields are populated. RemoveProfilePictureId is null.
     * Fetch the profile of a user.
     */
    async getUserProfile(requestParameters: GetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.getUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All returned fields are populated.
     * Fetch the settings for a user.
     */
    async getUserSettingsRaw(requestParameters: GetUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettingsDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsDTOFromJSON(jsonValue));
    }

    /**
     * All returned fields are populated.
     * Fetch the settings for a user.
     */
    async getUserSettings(requestParameters: GetUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettingsDTO> {
        const response = await this.getUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handle webhook
     */
    async handleWebhookRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment/handleWebhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle webhook
     */
    async handleWebhook(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.handleWebhookRaw(initOverrides);
    }

    /**
     * Returns null, if the user is not blocked. Returns a timestamp corresponding to the block time, if the user is blocked.
     * Check whether a given user has another user blocked.
     */
    async isBlockedUserRaw(requestParameters: IsBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling isBlockedUser.');
        }

        if (requestParameters.isBlockedId === null || requestParameters.isBlockedId === undefined) {
            throw new runtime.RequiredError('isBlockedId','Required parameter requestParameters.isBlockedId was null or undefined when calling isBlockedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/blockedUsers/{isBlockedId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"isBlockedId"}}`, encodeURIComponent(String(requestParameters.isBlockedId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns null, if the user is not blocked. Returns a timestamp corresponding to the block time, if the user is blocked.
     * Check whether a given user has another user blocked.
     */
    async isBlockedUser(requestParameters: IsBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.isBlockedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Post a new signaling candidate
     */
    async postNewSignalingCandidateRaw(requestParameters: PostNewSignalingCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calling/call/candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostNewSignalingCandidateRequestToJSON(requestParameters.postNewSignalingCandidateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post a new signaling candidate
     */
    async postNewSignalingCandidate(requestParameters: PostNewSignalingCandidateOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postNewSignalingCandidateRaw(requestParameters, initOverrides);
    }

    /**
     * One cannot remove a user which has not been blocked.
     * Remove a user from the blocklist.
     */
    async removeBlockedUserRaw(requestParameters: RemoveBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling removeBlockedUser.');
        }

        if (requestParameters.unblockUserId === null || requestParameters.unblockUserId === undefined) {
            throw new runtime.RequiredError('unblockUserId','Required parameter requestParameters.unblockUserId was null or undefined when calling removeBlockedUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.unblockUserId !== undefined) {
            queryParameters['unblockUserId'] = requestParameters.unblockUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/blockedUsers`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * One cannot remove a user which has not been blocked.
     * Remove a user from the blocklist.
     */
    async removeBlockedUser(requestParameters: RemoveBlockedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeBlockedUserRaw(requestParameters, initOverrides);
    }

    /**
     * One cannot remove someone who is not a member of the group. If the last admin of the group removes themselves, another member is promoted to admin at random.
     * Remove a member from a group.
     */
    async removeGroupMemberRaw(requestParameters: RemoveGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling removeGroupMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling removeGroupMember.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/members`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * One cannot remove someone who is not a member of the group. If the last admin of the group removes themselves, another member is promoted to admin at random.
     * Remove a member from a group.
     */
    async removeGroupMember(requestParameters: RemoveGroupMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeGroupMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Request a password reset mail
     */
    async requestPasswordResetMailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/resetPassword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a password reset mail
     */
    async requestPasswordResetMail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestPasswordResetMailRaw(initOverrides);
    }

    /**
     * Send a message
     */
    async sendMessageRaw(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling sendMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/messaging/message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageToJSON(requestParameters.message),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * Send a message
     */
    async sendMessage(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.sendMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * One cannot update the chat status of a user who is not a member of the chat. Direct chats are not allowed to be set to NOT_A_MEMBER. Group chats are not allowed to be set to neither NOT_A_MEMBER nor DELETED. This endpoint cannot be used to exit a group. When both members of a direct chat have the chat as DELETED, then the chat will be deleted.
     * Update the chat status for a given chat and user.
     */
    async setChatStatusRaw(requestParameters: SetChatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling setChatStatus.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling setChatStatus.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setChatStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/chats/{chatId}/members/{userId}/status`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * One cannot update the chat status of a user who is not a member of the chat. Direct chats are not allowed to be set to NOT_A_MEMBER. Group chats are not allowed to be set to neither NOT_A_MEMBER nor DELETED. This endpoint cannot be used to exit a group. When both members of a direct chat have the chat as DELETED, then the chat will be deleted.
     * Update the chat status for a given chat and user.
     */
    async setChatStatus(requestParameters: SetChatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setChatStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All given fields are updated. To remove the profilePictureId, set the field to null and removeProfilePictureId to true.
     * Update the group profile for a given group.
     */
    async updateGroupProfileRaw(requestParameters: UpdateGroupProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupProfileDTO>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateGroupProfile.');
        }

        if (requestParameters.groupProfileDTO === null || requestParameters.groupProfileDTO === undefined) {
            throw new runtime.RequiredError('groupProfileDTO','Required parameter requestParameters.groupProfileDTO was null or undefined when calling updateGroupProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/profile`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: GroupProfileDTOToJSON(requestParameters.groupProfileDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupProfileDTOFromJSON(jsonValue));
    }

    /**
     * All given fields are updated. To remove the profilePictureId, set the field to null and removeProfilePictureId to true.
     * Update the group profile for a given group.
     */
    async updateGroupProfile(requestParameters: UpdateGroupProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupProfileDTO> {
        const response = await this.updateGroupProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * One cannot update the role of a user who is not in the group. This endpoint cannot be used to remove someone from a group. NOT_A_MEMBER is not a valid value for this endpoint. The requesting user must be an admin of the group to perform this action. There always must be at least one admin in a group.
     * Update the role of a group member for a group.
     */
    async updateGroupRoleRaw(requestParameters: UpdateGroupRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateGroupRole.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateGroupRole.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateGroupRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/groups/{groupId}/members/{userId}/role`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * One cannot update the role of a user who is not in the group. This endpoint cannot be used to remove someone from a group. NOT_A_MEMBER is not a valid value for this endpoint. The requesting user must be an admin of the group to perform this action. There always must be at least one admin in a group.
     * Update the role of a group member for a group.
     */
    async updateGroupRole(requestParameters: UpdateGroupRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateGroupRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update current notification token
     */
    async updateTokenRaw(requestParameters: UpdateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationToken === null || requestParameters.notificationToken === undefined) {
            throw new runtime.RequiredError('notificationToken','Required parameter requestParameters.notificationToken was null or undefined when calling updateToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.notificationToken !== undefined) {
            queryParameters['notificationToken'] = requestParameters.notificationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/notification/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update current notification token
     */
    async updateToken(requestParameters: UpdateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTokenRaw(requestParameters, initOverrides);
    }

    /**
     * All given fields are updated. To remove the profilePictureId, set the field to null and removeProfilePictureId to true. Returns the same object as getUserProfile.
     * Update the profile of a user.
     */
    async updateUserProfileRaw(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserProfile.');
        }

        if (requestParameters.userProfileDTO === null || requestParameters.userProfileDTO === undefined) {
            throw new runtime.RequiredError('userProfileDTO','Required parameter requestParameters.userProfileDTO was null or undefined when calling updateUserProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/profile`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileDTOToJSON(requestParameters.userProfileDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     * All given fields are updated. To remove the profilePictureId, set the field to null and removeProfilePictureId to true. Returns the same object as getUserProfile.
     * Update the profile of a user.
     */
    async updateUserProfile(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.updateUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All given fields are updated. Returns the same object as getUserSettings.
     * Update the settings for a user.
     */
    async updateUserSettingsRaw(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettingsDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserSettings.');
        }

        if (requestParameters.userSettingsDTO === null || requestParameters.userSettingsDTO === undefined) {
            throw new runtime.RequiredError('userSettingsDTO','Required parameter requestParameters.userSettingsDTO was null or undefined when calling updateUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsDTOToJSON(requestParameters.userSettingsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsDTOFromJSON(jsonValue));
    }

    /**
     * All given fields are updated. Returns the same object as getUserSettings.
     * Update the settings for a user.
     */
    async updateUserSettings(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettingsDTO> {
        const response = await this.updateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate the jwt token
     */
    async validateTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/validateToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate the jwt token
     */
    async validateToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateTokenRaw(initOverrides);
    }

    /**
     * Validate the jwt token
     */
    async validateTokenInternalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/internal/validateToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate the jwt token
     */
    async validateTokenInternal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateTokenInternalRaw(initOverrides);
    }

}
