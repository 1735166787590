/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';
import type { PageInfo } from './PageInfo';
import {
    PageInfoFromJSON,
    PageInfoFromJSONTyped,
    PageInfoToJSON,
} from './PageInfo';

/**
 * 
 * @export
 * @interface GetMessages200Response
 */
export interface GetMessages200Response {
    /**
     * 
     * @type {Array<Message>}
     * @memberof GetMessages200Response
     */
    messages: Array<Message>;
    /**
     * 
     * @type {PageInfo}
     * @memberof GetMessages200Response
     */
    pageInfo: PageInfo;
}

/**
 * Check if a given object implements the GetMessages200Response interface.
 */
export function instanceOfGetMessages200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "pageInfo" in value;

    return isInstance;
}

export function GetMessages200ResponseFromJSON(json: any): GetMessages200Response {
    return GetMessages200ResponseFromJSONTyped(json, false);
}

export function GetMessages200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMessages200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'pageInfo': PageInfoFromJSON(json['pageInfo']),
    };
}

export function GetMessages200ResponseToJSON(value?: GetMessages200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': ((value.messages as Array<any>).map(MessageToJSON)),
        'pageInfo': PageInfoToJSON(value.pageInfo),
    };
}

