/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserProfileDTO } from './UserProfileDTO';
import {
    UserProfileDTOFromJSON,
    UserProfileDTOFromJSONTyped,
    UserProfileDTOToJSON,
} from './UserProfileDTO';

/**
 * 
 * @export
 * @interface ChatMemberDTO
 */
export interface ChatMemberDTO {
    /**
     * 
     * @type {string}
     * @memberof ChatMemberDTO
     */
    userId: string;
    /**
     * 
     * @type {UserProfileDTO}
     * @memberof ChatMemberDTO
     */
    userProfileDTO: UserProfileDTO;
    /**
     * 
     * @type {string}
     * @memberof ChatMemberDTO
     */
    groupRole?: ChatMemberDTOGroupRoleEnum;
}


/**
 * @export
 */
export const ChatMemberDTOGroupRoleEnum = {
    NotAMember: 'NOT_A_MEMBER',
    GroupMember: 'GROUP_MEMBER',
    GroupAdmin: 'GROUP_ADMIN'
} as const;
export type ChatMemberDTOGroupRoleEnum = typeof ChatMemberDTOGroupRoleEnum[keyof typeof ChatMemberDTOGroupRoleEnum];


/**
 * Check if a given object implements the ChatMemberDTO interface.
 */
export function instanceOfChatMemberDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userProfileDTO" in value;

    return isInstance;
}

export function ChatMemberDTOFromJSON(json: any): ChatMemberDTO {
    return ChatMemberDTOFromJSONTyped(json, false);
}

export function ChatMemberDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMemberDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'userProfileDTO': UserProfileDTOFromJSON(json['userProfileDTO']),
        'groupRole': !exists(json, 'groupRole') ? undefined : json['groupRole'],
    };
}

export function ChatMemberDTOToJSON(value?: ChatMemberDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userProfileDTO': UserProfileDTOToJSON(value.userProfileDTO),
        'groupRole': value.groupRole,
    };
}

