/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignalingAnswer
 */
export interface SignalingAnswer {
    /**
     * 
     * @type {string}
     * @memberof SignalingAnswer
     */
    sdp?: string;
    /**
     * 
     * @type {string}
     * @memberof SignalingAnswer
     */
    type: string;
}

/**
 * Check if a given object implements the SignalingAnswer interface.
 */
export function instanceOfSignalingAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function SignalingAnswerFromJSON(json: any): SignalingAnswer {
    return SignalingAnswerFromJSONTyped(json, false);
}

export function SignalingAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalingAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sdp': !exists(json, 'sdp') ? undefined : json['sdp'],
        'type': json['type'],
    };
}

export function SignalingAnswerToJSON(value?: SignalingAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sdp': value.sdp,
        'type': value.type,
    };
}

