/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly senderId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    chatId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readonly transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    mediaPath?: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    readonly sentTimestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message: string;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "senderId" in value;
    isInstance = isInstance && "chatId" in value;
    isInstance = isInstance && "sentTimestamp" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'senderId': json['senderId'],
        'chatId': json['chatId'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'mediaPath': !exists(json, 'mediaPath') ? undefined : json['mediaPath'],
        'sentTimestamp': (new Date(json['sentTimestamp'])),
        'message': json['message'],
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatId': value.chatId,
        'mediaPath': value.mediaPath,
        'message': value.message,
    };
}

