/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignalingCandidate
 */
export interface SignalingCandidate {
    /**
     * 
     * @type {string}
     * @memberof SignalingCandidate
     */
    candidate?: string;
    /**
     * 
     * @type {number}
     * @memberof SignalingCandidate
     */
    sdpMLineIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof SignalingCandidate
     */
    sdpMid?: string;
    /**
     * 
     * @type {string}
     * @memberof SignalingCandidate
     */
    usernameFragment?: string;
}

/**
 * Check if a given object implements the SignalingCandidate interface.
 */
export function instanceOfSignalingCandidate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SignalingCandidateFromJSON(json: any): SignalingCandidate {
    return SignalingCandidateFromJSONTyped(json, false);
}

export function SignalingCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalingCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidate': !exists(json, 'candidate') ? undefined : json['candidate'],
        'sdpMLineIndex': !exists(json, 'sdpMLineIndex') ? undefined : json['sdpMLineIndex'],
        'sdpMid': !exists(json, 'sdpMid') ? undefined : json['sdpMid'],
        'usernameFragment': !exists(json, 'usernameFragment') ? undefined : json['usernameFragment'],
    };
}

export function SignalingCandidateToJSON(value?: SignalingCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate': value.candidate,
        'sdpMLineIndex': value.sdpMLineIndex,
        'sdpMid': value.sdpMid,
        'usernameFragment': value.usernameFragment,
    };
}

