/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalingAnswer } from './SignalingAnswer';
import {
    SignalingAnswerFromJSON,
    SignalingAnswerFromJSONTyped,
    SignalingAnswerToJSON,
} from './SignalingAnswer';

/**
 * 
 * @export
 * @interface AnswerCallRequest
 */
export interface AnswerCallRequest {
    /**
     * 
     * @type {string}
     * @memberof AnswerCallRequest
     */
    callId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerCallRequest
     */
    accept: boolean;
    /**
     * 
     * @type {SignalingAnswer}
     * @memberof AnswerCallRequest
     */
    answer?: SignalingAnswer;
}

/**
 * Check if a given object implements the AnswerCallRequest interface.
 */
export function instanceOfAnswerCallRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accept" in value;

    return isInstance;
}

export function AnswerCallRequestFromJSON(json: any): AnswerCallRequest {
    return AnswerCallRequestFromJSONTyped(json, false);
}

export function AnswerCallRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerCallRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callId': !exists(json, 'callId') ? undefined : json['callId'],
        'accept': json['accept'],
        'answer': !exists(json, 'answer') ? undefined : SignalingAnswerFromJSON(json['answer']),
    };
}

export function AnswerCallRequestToJSON(value?: AnswerCallRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'callId': value.callId,
        'accept': value.accept,
        'answer': SignalingAnswerToJSON(value.answer),
    };
}

