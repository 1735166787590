/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSettingsDTO
 */
export interface UserSettingsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDTO
     */
    readReceipts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDTO
     */
    lastSeen?: boolean;
}

/**
 * Check if a given object implements the UserSettingsDTO interface.
 */
export function instanceOfUserSettingsDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSettingsDTOFromJSON(json: any): UserSettingsDTO {
    return UserSettingsDTOFromJSONTyped(json, false);
}

export function UserSettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readReceipts': !exists(json, 'readReceipts') ? undefined : json['readReceipts'],
        'lastSeen': !exists(json, 'lastSeen') ? undefined : json['lastSeen'],
    };
}

export function UserSettingsDTOToJSON(value?: UserSettingsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readReceipts': value.readReceipts,
        'lastSeen': value.lastSeen,
    };
}

