/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupProfileDTO } from './GroupProfileDTO';
import {
    GroupProfileDTOFromJSON,
    GroupProfileDTOFromJSONTyped,
    GroupProfileDTOToJSON,
} from './GroupProfileDTO';
import type { UserProfileDTO } from './UserProfileDTO';
import {
    UserProfileDTOFromJSON,
    UserProfileDTOFromJSONTyped,
    UserProfileDTOToJSON,
} from './UserProfileDTO';

/**
 * 
 * @export
 * @interface ChatDTO
 */
export interface ChatDTO {
    /**
     * 
     * @type {string}
     * @memberof ChatDTO
     */
    chatId: string;
    /**
     * 
     * @type {string}
     * @memberof ChatDTO
     */
    chatType: ChatDTOChatTypeEnum;
    /**
     * 
     * @type {GroupProfileDTO}
     * @memberof ChatDTO
     */
    groupProfileDTO?: GroupProfileDTO;
    /**
     * 
     * @type {string}
     * @memberof ChatDTO
     */
    userId?: string;
    /**
     * 
     * @type {UserProfileDTO}
     * @memberof ChatDTO
     */
    userProfileDTO?: UserProfileDTO;
}


/**
 * @export
 */
export const ChatDTOChatTypeEnum = {
    DirectChat: 'DIRECT_CHAT',
    GroupChat: 'GROUP_CHAT'
} as const;
export type ChatDTOChatTypeEnum = typeof ChatDTOChatTypeEnum[keyof typeof ChatDTOChatTypeEnum];


/**
 * Check if a given object implements the ChatDTO interface.
 */
export function instanceOfChatDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chatId" in value;
    isInstance = isInstance && "chatType" in value;

    return isInstance;
}

export function ChatDTOFromJSON(json: any): ChatDTO {
    return ChatDTOFromJSONTyped(json, false);
}

export function ChatDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatId': json['chatId'],
        'chatType': json['chatType'],
        'groupProfileDTO': !exists(json, 'groupProfileDTO') ? undefined : GroupProfileDTOFromJSON(json['groupProfileDTO']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userProfileDTO': !exists(json, 'userProfileDTO') ? undefined : UserProfileDTOFromJSON(json['userProfileDTO']),
    };
}

export function ChatDTOToJSON(value?: ChatDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatId': value.chatId,
        'chatType': value.chatType,
        'groupProfileDTO': GroupProfileDTOToJSON(value.groupProfileDTO),
        'userId': value.userId,
        'userProfileDTO': UserProfileDTOToJSON(value.userProfileDTO),
    };
}

