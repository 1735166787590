/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupProfileDTO
 */
export interface GroupProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupProfileDTO
     */
    groupName: string;
    /**
     * 
     * @type {string}
     * @memberof GroupProfileDTO
     */
    profilePictureId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupProfileDTO
     */
    removeProfilePictureId?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GroupProfileDTO
     */
    profileDescription?: string;
}

/**
 * Check if a given object implements the GroupProfileDTO interface.
 */
export function instanceOfGroupProfileDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupName" in value;

    return isInstance;
}

export function GroupProfileDTOFromJSON(json: any): GroupProfileDTO {
    return GroupProfileDTOFromJSONTyped(json, false);
}

export function GroupProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupProfileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupName': json['groupName'],
        'profilePictureId': !exists(json, 'profilePictureId') ? undefined : json['profilePictureId'],
        'removeProfilePictureId': !exists(json, 'removeProfilePictureId') ? undefined : json['removeProfilePictureId'],
        'profileDescription': !exists(json, 'profileDescription') ? undefined : json['profileDescription'],
    };
}

export function GroupProfileDTOToJSON(value?: GroupProfileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupName': value.groupName,
        'profilePictureId': value.profilePictureId,
        'removeProfilePictureId': value.removeProfilePictureId,
        'profileDescription': value.profileDescription,
    };
}

