/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Call
 */
export interface Call {
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    callerId: string;
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    calleeId: string;
    /**
     * 
     * @type {Date}
     * @memberof Call
     */
    readonly timestamp: Date;
}

/**
 * Check if a given object implements the Call interface.
 */
export function instanceOfCall(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "callerId" in value;
    isInstance = isInstance && "calleeId" in value;
    isInstance = isInstance && "timestamp" in value;

    return isInstance;
}

export function CallFromJSON(json: any): Call {
    return CallFromJSONTyped(json, false);
}

export function CallFromJSONTyped(json: any, ignoreDiscriminator: boolean): Call {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'callerId': json['callerId'],
        'calleeId': json['calleeId'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function CallToJSON(value?: Call | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'callerId': value.callerId,
        'calleeId': value.calleeId,
    };
}

