/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserProfileDTO } from './UserProfileDTO';
import {
    UserProfileDTOFromJSON,
    UserProfileDTOFromJSONTyped,
    UserProfileDTOToJSON,
} from './UserProfileDTO';

/**
 * 
 * @export
 * @interface BlockedUserDTO
 */
export interface BlockedUserDTO {
    /**
     * 
     * @type {string}
     * @memberof BlockedUserDTO
     */
    id: string;
    /**
     * 
     * @type {UserProfileDTO}
     * @memberof BlockedUserDTO
     */
    userProfileDTO: UserProfileDTO;
    /**
     * 
     * @type {Date}
     * @memberof BlockedUserDTO
     */
    blockedAt: Date;
}

/**
 * Check if a given object implements the BlockedUserDTO interface.
 */
export function instanceOfBlockedUserDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userProfileDTO" in value;
    isInstance = isInstance && "blockedAt" in value;

    return isInstance;
}

export function BlockedUserDTOFromJSON(json: any): BlockedUserDTO {
    return BlockedUserDTOFromJSONTyped(json, false);
}

export function BlockedUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockedUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userProfileDTO': UserProfileDTOFromJSON(json['userProfileDTO']),
        'blockedAt': (new Date(json['blockedAt'])),
    };
}

export function BlockedUserDTOToJSON(value?: BlockedUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userProfileDTO': UserProfileDTOToJSON(value.userProfileDTO),
        'blockedAt': (value.blockedAt.toISOString()),
    };
}

