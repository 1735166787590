/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalingCandidate } from './SignalingCandidate';
import {
    SignalingCandidateFromJSON,
    SignalingCandidateFromJSONTyped,
    SignalingCandidateToJSON,
} from './SignalingCandidate';

/**
 * 
 * @export
 * @interface PostNewSignalingCandidateRequest
 */
export interface PostNewSignalingCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof PostNewSignalingCandidateRequest
     */
    callId: string;
    /**
     * 
     * @type {SignalingCandidate}
     * @memberof PostNewSignalingCandidateRequest
     */
    candidate: SignalingCandidate;
}

/**
 * Check if a given object implements the PostNewSignalingCandidateRequest interface.
 */
export function instanceOfPostNewSignalingCandidateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "callId" in value;
    isInstance = isInstance && "candidate" in value;

    return isInstance;
}

export function PostNewSignalingCandidateRequestFromJSON(json: any): PostNewSignalingCandidateRequest {
    return PostNewSignalingCandidateRequestFromJSONTyped(json, false);
}

export function PostNewSignalingCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewSignalingCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callId': json['callId'],
        'candidate': SignalingCandidateFromJSON(json['candidate']),
    };
}

export function PostNewSignalingCandidateRequestToJSON(value?: PostNewSignalingCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'callId': value.callId,
        'candidate': SignalingCandidateToJSON(value.candidate),
    };
}

