/* tslint:disable */
/* eslint-disable */
/**
 * Y-Chat Auth API
 * Y-Chat Auth API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserProfileDTO } from './UserProfileDTO';
import {
    UserProfileDTOFromJSON,
    UserProfileDTOFromJSONTyped,
    UserProfileDTOToJSON,
} from './UserProfileDTO';
import type { UserSettingsDTO } from './UserSettingsDTO';
import {
    UserSettingsDTOFromJSON,
    UserSettingsDTOFromJSONTyped,
    UserSettingsDTOToJSON,
} from './UserSettingsDTO';

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id: string;
    /**
     * 
     * @type {UserProfileDTO}
     * @memberof UserDTO
     */
    userProfileDTO: UserProfileDTO;
    /**
     * 
     * @type {UserSettingsDTO}
     * @memberof UserDTO
     */
    userSettingsDTO: UserSettingsDTO;
}

/**
 * Check if a given object implements the UserDTO interface.
 */
export function instanceOfUserDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userProfileDTO" in value;
    isInstance = isInstance && "userSettingsDTO" in value;

    return isInstance;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userProfileDTO': UserProfileDTOFromJSON(json['userProfileDTO']),
        'userSettingsDTO': UserSettingsDTOFromJSON(json['userSettingsDTO']),
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userProfileDTO': UserProfileDTOToJSON(value.userProfileDTO),
        'userSettingsDTO': UserSettingsDTOToJSON(value.userSettingsDTO),
    };
}

